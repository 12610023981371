<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("containers", pageName, pageName) }}
        </div>
        <template v-slot:right>
          <button @click="saveContainer">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="editMegaContainerForm"
      @submit="submitForm"
      :key="formKey"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="selectedContainer && { ...selectedContainer }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <p class="clebex-section-input-label">
                {{ displayLabelName("containers", pageName, "container-id") }}
              </p>
              <p class="text-label">
                {{ selectedContainer && selectedContainer.id }}
              </p>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("containers", pageName, "name")
              }}</label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                :placeholder="displayLabelName('containers', pageName, 'name')"
              />
            </div>
          </div>
        </li>
        <li
          v-if="pageName === 'edit-user-container'"
          class="clebex-item-section-item"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "azure-user-group-id"
                  )
                }}
              </label>
              <Field
                name="azure_group_id"
                as="input"
                type="text"
                :placeholder="
                  displayLabelName(
                    'containers',
                    pageName,
                    'azure-user-group-id'
                  )
                "
              />
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="containersContainAll"
                    type="checkbox"
                    v-model="containAll"
                  />
                  <label for="containersContainAll"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("containers", pageName, "contain-all") }}
              </dd>
            </dl>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="containerType && containerType === 'USER'"
        >
          <router-link
            v-if="!containAll"
            :to="{ name: addUserLinkName }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedUsers && selectedUsers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-users-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  selectedUsers
                    .map(item => `${item.first_name} ${item.last_name}`)
                    .join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-users-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div
              class="clebex-section-input"
              v-if="selectedUsers && selectedUsers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-users-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  selectedUsers
                    .map(item => `${item.first_name} ${item.last_name}`)
                    .join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-users-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="containerType && containerType === 'LEVEL'"
        >
          <router-link
            :to="{ name: 'r_edit-level-add-level-to-container' }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedLevels && selectedLevels.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-levels-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedLevels.map(item => `${item.name}`).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-levels-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="containerType && containerType === 'RESOURCE'"
        >
          <router-link
            :to="{ name: 'r_edit-resource-add-resources-to-container' }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResources && selectedResources.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-resources-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedResources.map(item => `${item.name}`).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-resources-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="containerType && containerType === 'USER_MANAGER'"
        >
          <router-link
            v-if="!containAll"
            :to="{
              name: addUserLinkName
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedUsers && selectedUsers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-users-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  selectedUsers
                    .map(item => `${item.first_name} ${item.last_name}`)
                    .join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-users-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div
              class="clebex-section-input"
              v-if="selectedUsers && selectedUsers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-users-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  selectedUsers
                    .map(item => `${item.first_name} ${item.last_name}`)
                    .join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-users-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="containerType && containerType === 'RECEPTION_DESK'"
        >
          <router-link
            v-if="!containAll"
            :to="{
              name: addReceptionDeskLinkName
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedReceptionDesks && selectedReceptionDesks.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-reception-desks-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  selectedReceptionDesks.map(item => `${item.name}`).join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-reception-desks-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div
              class="clebex-section-input"
              v-if="selectedReceptionDesks && selectedReceptionDesks.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-reception-desks-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  selectedReceptionDesks.map(item => `${item.name}`).join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-reception-desks-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <router-link
            :to="{ name: addRoleLinkName }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedRoles && selectedRoles.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-roles-to-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedRoles.map(item => `${item.name}`).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-roles-to-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <screen-modal
      v-if="showModal"
      class="confirm-modal"
      type="warning"
      :confirm-button-label="displayLabelName('containers', pageName, 'ok')"
      :cancel-button-label="displayLabelName('containers', pageName, 'cancel')"
      :confirm-action="initSubmit"
      :hideTimer="true"
      :show="showModal"
      @close="showModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("containers", pageName, "no-users") }}
      </h3>
      <br />
      <p>
        {{ displayLabelName("containers", pageName, "no-users-message") }}
      </p>
      <br />
      <p>
        {{ displayLabelName("containers", pageName, "no-users-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import containersMixin from "@/services/mixins/containers/containers-mixin";
export default {
  name: "EditContainer",
  data() {
    return {
      containAll: false,
      formKey: 0,
      showModal: false
    };
  },
  computed: {
    ...mapState("containers", [
      "selectedContainer",
      "selectedUsers",
      "selectedLevels",
      "selectedResources",
      "selectedRoles",
      "receptionDesksInContainer",
      "selectedReceptionDesks"
    ]),
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "edit-user-container";
      } else if (containerType === "RESOURCE") {
        return "edit-resource-container";
      } else if (containerType === "LEVEL") {
        return "edit-level-container";
      } else if (containerType === "USER_MANAGER") {
        return "edit-user-manage-container";
      } else if (containerType === "RECEPTION_DESK") {
        return "edit-reception-desk-container";
      }
      return "";
    },
    addRoleLinkName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "r_edit-user-add-roles-to-container";
      } else if (containerType === "RESOURCE") {
        return "r_edit-resource-add-roles-to-container";
      } else if (containerType === "LEVEL") {
        return "r_edit-level-add-roles-to-container";
      } else if (containerType === "USER_MANAGER") {
        return "r_edit-user-manage-container-add-roles-to-container";
      } else if (containerType === "RECEPTION_DESK") {
        return "r_edit-reception-desk-add-roles-to-container";
      }
      return "";
    },
    addUserLinkName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "r_edit-user-add-users-to-container";
      } else if (containerType === "USER_MANAGER") {
        return "r_edit-user-add-users-manager-to-container";
      }
      return "";
    },
    addReceptionDeskLinkName() {
      return "r_edit-reception-desk-add-reception-desk-to-container";
    }
  },
  watch: {
    selectedContainer: {
      immediate: true,
      handler() {
        if (this.formKey === 10) {
          this.formKey = 0;
        } else {
          this.formKey++;
        }
        this.containAll = Boolean(
          this.selectedContainer && this.selectedContainer.contain_all
        );
        if (this.selectedContainer && this.selectedContainer.id) {
          if (this.containerType) {
            if (this.containerType === "USER") {
              this.getUsersInContainer(this.selectedContainer.id);
            }

            if (this.containerType === "LEVEL") {
              this.getLevelsInContainer(this.selectedContainer.id);
            }

            if (this.containerType === "RESOURCE") {
              this.getResourcesInContainer(this.selectedContainer.id);
            }

            if (this.containerType === "USER_MANAGER") {
              this.getUserManagersInContainer(this.selectedContainer.id);
            }

            if (this.containerType === "RECEPTION_DESK") {
              this.getReceptionDesksInContainer(this.selectedContainer.id);
            }

            this.getRolesInContainer(this.selectedContainer.id);
          }
        }
      }
    },
    containAll() {
      if (this.containerType === "USER" && this.containAll) {
        this.$router.push({
          name: "r_edit-user-container",
          params: this.$route.params
        });
      }
    }
  },
  mounted() {
    if (!this.selectedContainer) {
      this.$router.push({ name: this.backLinkName });
    }
  },
  methods: {
    ...mapActions("containers", [
      "setSelectedContainer",
      "getUsersInContainer",
      "updateUsersInContainer",
      "getLevelsInContainer",
      "updateLevelsInContainer",
      "getResourcesInContainer",
      "updateResourcesInContainer",
      "getRolesInContainer",
      "updateRolesInContainer",
      "getUserManagersInContainer",
      "updateReceptionDesksInContainer",
      "getReceptionDesksInContainer"
    ]),
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      values.access_type_id = this.containerTypeId;
      values.contain_all = Number(this.containAll);
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      let promises = [];
      let promise1;

      if (
        ["USER", "USER_MANAGER"].includes(this.containerType) &&
        !this.containAll
      ) {
        promise1 = this.updateUsersInContainer({
          container_id: this.selectedContainer.id,
          container_type: this.containerType
        });
      }

      if (this.containerType === "LEVEL") {
        promise1 = this.updateLevelsInContainer(this.selectedContainer.id);
      }

      if (this.containerType === "RESOURCE") {
        promise1 = this.updateResourcesInContainer(this.selectedContainer.id);
      }

      if (this.containerType === "RECEPTION_DESK") {
        promise1 = this.updateReceptionDesksInContainer(
          this.selectedContainer.id
        );
      }

      if (this.containerType === "") {
        promise1 = this.updateResourcesInContainer(this.selectedContainer.id);
      }

      if (promise1) {
        promises = [promise1];
      }

      if (this.selectedRoles && this.selectedRoles.length) {
        let promise2 = this.updateRolesInContainer(this.selectedContainer.id);
        promises = [...promises, promise2];
      }

      if (promises.length) {
        Promise.allSettled(promises).then(() => {
          httpServiceAuth
            .put(
              `${apiEndpoints.company.containers}/${this.selectedContainer.id}`,
              values
            )
            .then(() => {
              if (this.containerType) {
                if (this.containerType === "USER") {
                  setTimeout(() => {
                    this.getUsersInContainer(this.selectedContainer.id);
                  }, 0);
                }

                if (this.containerType === "USER_MANAGER") {
                  setTimeout(() => {
                    this.getUserManagersInContainer(this.selectedContainer.id);
                  }, 0);
                }

                if (this.containerType === "LEVEL") {
                  setTimeout(() => {
                    this.getLevelsInContainer(this.selectedContainer.id);
                  }, 0);
                }

                if (this.containerType === "RESOURCE") {
                  setTimeout(() => {
                    this.getResourcesInContainer(this.selectedContainer.id);
                  }, 0);
                }

                setTimeout(() => {
                  this.getRolesInContainer(this.selectedContainer.id);
                }, 0);
              }

              this.fetchContainers();
              this.setSelectedContainer(...this.selectedContainer, ...values);
            })
            .catch(error => {
              if (error.response) {
                errorHandler(error.response, this.findElement());
              }
            })
            .finally(() => {
              this.$store.commit("loader/setScreenLoading", false, {
                root: true
              });
            });
        });
      } else {
        httpServiceAuth
          .put(
            `${apiEndpoints.company.containers}/${this.selectedContainer.id}`,
            values
          )
          .then(() => {
            if (this.containerType) {
              if (this.containerType === "USER") {
                setTimeout(() => {
                  this.getUsersInContainer(this.selectedContainer.id);
                }, 0);
              }

              if (this.containerType === "USER_MANAGER") {
                setTimeout(() => {
                  this.getUserManagersInContainer(this.selectedContainer.id);
                }, 0);
              }

              if (this.containerType === "LEVEL") {
                setTimeout(() => {
                  this.getLevelsInContainer(this.selectedContainer.id);
                }, 0);
              }

              if (this.containerType === "RESOURCE") {
                setTimeout(() => {
                  this.getResourcesInContainer(this.selectedContainer.id);
                }, 0);
              }

              setTimeout(() => {
                this.getRolesInContainer(this.selectedContainer.id);
              }, 0);
            }

            this.fetchContainers();
            this.setSelectedContainer(...this.selectedContainer, ...values);
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    saveContainer() {
      if (
        (this.containerType && this.containerType !== "USER") ||
        this.containAll ||
        (this.selectedUsers && this.selectedUsers.length)
      ) {
        this.initSubmit();
      } else {
        this.showModal = true;
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [containersMixin]
};
</script>
